var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v("Data")]),_c('b-card-title',{staticClass:"text-primary"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"variant":"primary","size":"sm","to":{ name: 'tagihanTambah' }}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"6","offset-md":"3"}},[_c('h4',[_vm._v("Filter")]),_c('b-form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.submitFilter.apply(null, arguments)}}},[_c('b-col',{attrs:{"offset-md":"1","cols":"10"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('b-form-select',{attrs:{"id":"status","options":_vm.dataStatus},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1),_c('b-col',{attrs:{"offset-md":"9","cols":"2","align":"right"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","size":"sm","type":"submit"}},[_vm._v(" Filter ")])],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Cari")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Cari","type":"text"},on:{"input":_vm.handleSearch},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
              enabled: true,
              externalQuery: _vm.searchTerm,
            },"pagination-options":{
              enabled: true,
              perPage: _vm.row,
              jumpFirstOrLast: true,
            },"totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"mode":"remote"},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'noTagihan')?_c('span',{staticClass:"align-self-center"},[_c('router-link',{attrs:{"to":{ name: 'tagihanUbah', params: { id: props.row.id } }}},[_vm._v(_vm._s(props.formattedRow[props.column.field]))]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-primary","title":"Tagihan"},on:{"click":function($event){return _vm.generatePdf(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1)],1):(props.column.field === 'aksi')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(props.row.statusKonfirmasi)?_c('b-dropdown-item',{attrs:{"to":{
                        name: 'tagihanBayarTambah',
                        params: { id: props.row.id },
                      }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CreditCardIcon"}}),_c('span',[_vm._v("Bayar")])],1):_vm._e(),(!props.row.statusKonfirmasi)?_c('b-dropdown-item',{attrs:{"to":{
                        name: 'tagihanKonfirmasi',
                        params: { id: props.row.id },
                      }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Konfirmasi")])],1):_vm._e(),(!props.row.statusKonfirmasi)?_c('b-dropdown-item',{attrs:{"to":{
                        name: 'tagihanUbah',
                        params: { id: props.row.id },
                      }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Ubah")])],1):_vm._e(),(props.row.status == 'Belum Dibayar')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteButton(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Hapus")])],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Menampilkan 1 hingga ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '100', '1000']},on:{"input":_vm.handlePageChange},model:{value:(_vm.row),callback:function ($$v) {_vm.row=$$v},expression:"row"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" dari "+_vm._s(_vm.totalRecords)+" entri ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":_vm.totalRecords,"per-page":_vm.row,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.handleChangePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}])})],1)])])],2)],1)],1)],1)],1),_c('div',{staticClass:"mb-2",attrs:{"id":"showPdf"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }