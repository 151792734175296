<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Data</b-card-title>
        <b-card-title class="text-primary">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            size="sm"
            :to="{ name: 'tagihanTambah' }"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col cols="6" offset-md="3">
            <h4>Filter</h4>
            <b-form class="needs-validation" @submit.prevent="submitFilter">
              <b-col offset-md="1" cols="10">
                <b-form-group label="Status" label-for="status">
                  <b-form-select
                    v-model="statusFilter"
                    id="status"
                    :options="dataStatus"
                  />
                </b-form-group>
              </b-col>
              <b-col offset-md="9" cols="2" align="right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  type="submit"
                >
                  Filter
                </b-button>
              </b-col>
            </b-form>
          </b-col>
          <b-col cols="12">
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Cari</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Cari"
                    type="text"
                    class="d-inline-block"
                    @input="handleSearch"
                  />
                </div>
              </b-form-group>
            </div>
            <!-- table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: row,
                jumpFirstOrLast: true,
              }"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              mode="remote"
              @on-sort-change="onSortChange"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: noTagihan -->
                <span
                  v-if="props.column.field === 'noTagihan'"
                  class="align-self-center"
                >
                  <router-link
                    :to="{ name: 'tagihanUbah', params: { id: props.row.id } }"
                    >{{ props.formattedRow[props.column.field] }}</router-link
                  >
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="generatePdf(props.row.id)"
                    v-b-tooltip.hover
                    title="Tagihan"
                  >
                    <feather-icon icon="FileTextIcon" />
                  </b-button>
                </span>

                <!-- Column: aksi -->
                <span v-else-if="props.column.field === 'aksi'">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item
                        :to="{
                          name: 'tagihanBayarTambah',
                          params: { id: props.row.id },
                        }"
                        v-if="props.row.statusKonfirmasi"
                      >
                        <feather-icon icon="CreditCardIcon" class="mr-50" />
                        <span>Bayar</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :to="{
                          name: 'tagihanKonfirmasi',
                          params: { id: props.row.id },
                        }"
                        v-if="!props.row.statusKonfirmasi"
                      >
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Konfirmasi</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :to="{
                          name: 'tagihanUbah',
                          params: { id: props.row.id },
                        }"
                        v-if="!props.row.statusKonfirmasi"
                      >
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Ubah</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="deleteButton(props.row.id)"
                        v-if="props.row.status == 'Belum Dibayar'"
                      >
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Hapus</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Menampilkan 1 hingga </span>
                    <b-form-select
                      v-model="row"
                      :options="['10', '100', '1000']"
                      class="mx-1"
                      @input="handlePageChange"
                    />
                    <span class="text-nowrap">
                      dari {{ totalRecords }} entri
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="totalRecords"
                      :per-page="row"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @change="handleChangePage"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <div id="showPdf" class="mb-2"></div>
  </div>
</template>

<script>
import {
  VBTooltip,
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormInput,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BFormGroup,
  BFormSelect,
  BForm,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import { jsPDF } from "jspdf";
import autotable from "jspdf-autotable";
import pdfobject from "pdfobject";
const moment = require("moment");

export default {
  setup(props, context) {
    //global variable
    const g = context.root;
    const $http = g.$http;

    //variable untuk table
    let rows = ref([]);
    let searchTerm = ref("");
    let start = ref(1);
    let row = ref(10);
    let totalRecords = ref(0);
    let sortColumn = ref("");
    let order = ref("");
    let isLoading = ref(false);

    var statusFilter = ref(g.$route.query.status || "");
    var dataStatus = ref([
      { text: "-- Semua --", value: "" },
      { text: "Telah Dibayar", value: "Telah Dibayar" },
      { text: "Belum Dibayar", value: "Belum Dibayar" },
      { text: "Sebagian Dibayar", value: "Sebagian Dibayar" },
    ]);

    //fungsi ambil data untuk table
    const datatable = () => {
      isLoading.value = true;
      $http({
        url: "/tagihan/get-data",
        params: {
          start: start.value - 1,
          row: row.value,
          search: searchTerm.value,
          sortColumn: sortColumn.value,
          order: order.value,
          statusFilter: statusFilter.value,
        },
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          isLoading.value = false;
          rows.value = res.data.data;
          totalRecords.value = res.data.totalRecord;
        })
        .catch((err) => {
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Load Table`,
            variant: "danger",
            solid: true,
          });
        });
    };

    datatable();

    const submitFilter = () => {
      let url = "?status=" + statusFilter.value;
      window.history.pushState("", "", url);
      start.value = 1;
      datatable();
    };

    const generatePdf = (id) => {
      $http({
        url: `/tagihan/get-print-data/${id}`,
        params: {
          id,
        },
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          let {
            detailTagihans,
            noTagihan: noTagihanVal,
            emailTujuan,
            namaTujuan,
            status: statusVal,
            tanggalPenerbitan,
            pajak,
            potonganHarga,
            tambahanBiaya,
            subtotal,
            total,
            namaBank,
            labelBank,
            noRekening,
            kodeBank,
            namaAdminKeuangan,
            ttd,
          } = res.data.data;
          tanggalPenerbitan = moment(tanggalPenerbitan).format("DD-MM-YYYY");
          const formatUang = {
            currency: "IDR",
            style: "currency",
            maximumFractionDigits: 0,
          };
          pajak = pajak
            ? new Intl.NumberFormat("id-ID", formatUang).format(pajak)
            : "-";
          potonganHarga = potonganHarga
            ? new Intl.NumberFormat("id-ID", formatUang).format(potonganHarga)
            : "-";
          tambahanBiaya = tambahanBiaya
            ? new Intl.NumberFormat("id-ID", formatUang).format(tambahanBiaya)
            : "-";
          subtotal = subtotal
            ? new Intl.NumberFormat("id-ID", formatUang).format(subtotal)
            : "-";
          total = total
            ? new Intl.NumberFormat("id-ID", formatUang).format(total)
            : "-";

          /*
           * Let's demonstrate string splitting with the first page of Shakespeare's Romeo and Juliet!
           * We'll use a 8.5 x 11 inch sheet, measuring everything in inches.
           */
          var pageWidth = 8.3,
            lineHeight = 1.5,
            margin = 0.6,
            marginTop = 0.48,
            maxLineWidth = pageWidth - margin,
            fontSize = 10,
            ptsPerInch = 72,
            oneLineHeight = (fontSize * lineHeight) / ptsPerInch;

          var doc = new jsPDF({
            unit: "in",
            // lineHeight: lineHeight,
            format: "a4",
          })
            .setProperties({ title: "tagihan" })
            .setFont("times", "normal");

          // text is placed using x, y coordinates
          doc
            .setFont("times", "bold")
            .setFontSize(10)
            .text("Layanan Diagnostik", margin, marginTop);
          doc.text("Status Pembayaran", 7.6, marginTop, { align: "right" });

          moment.locale("id");
          doc
            .setFontSize(15)
            .text(
              `Tagihan ${moment(tanggalPenerbitan, "DD-MM-YYYY").format(
                "MMMM YYYY"
              )}`,
              margin,
              marginTop + oneLineHeight
            );
          doc.text(statusVal, 7.6, marginTop + oneLineHeight, {
            align: "right",
          });
          var tinggiGarisPembatas = marginTop + oneLineHeight + 0.05;
          doc
            .setLineWidth(0.01)
            .line(
              margin,
              tinggiGarisPembatas,
              maxLineWidth - 0.1,
              tinggiGarisPembatas
            );

          doc.text(
            "Nomor Tagihan " + noTagihanVal,
            margin,
            marginTop + 3 * oneLineHeight
          );
          doc
            .setFont("times", "normal")
            .setFontSize(10)
            .text(
              "Tanggal Penerbitan: " + tanggalPenerbitan,
              margin,
              marginTop + 4 * oneLineHeight
            );
          doc
            .setFont("times", "bold")
            .setFontSize(12)
            .text("Ditagihkan Ke", margin, marginTop + 6 * oneLineHeight);
          doc
            .setFont("times", "normal")
            .setFontSize(10)
            .text(namaTujuan ?? "", margin, marginTop + 7 * oneLineHeight);
          doc.text(emailTujuan ?? "", margin, marginTop + 8 * oneLineHeight);

          let noUrut = 1;
          let dataHal1 = detailTagihans.splice(0, 15);
          for (let i = 0; i < dataHal1.length; i++) {
            dataHal1[i].no = noUrut;
            dataHal1[i].biaya = dataHal1[i].biaya
              ? new Intl.NumberFormat("id-ID", formatUang).format(
                  dataHal1[i].biaya
                )
              : "-";
            noUrut++;
          }

          let opsi = {
            headStyles: { fillColor: [170, 170, 170], halign: "center" },
            columns: [
              { header: "No", dataKey: "no" },
              { header: "Nomor Lab", dataKey: "noLab" },
              { header: "Rekam Medis", dataKey: "rekamMedis" },
              { header: "Pasien", dataKey: "pasien" },
              { header: "Tanggal Terima", dataKey: "tanggalTerima" },
              { header: "Sediaan", dataKey: "sediaan" },
              { header: "Biaya", dataKey: "biaya" },
            ],
            columnStyles: {
              6: {
                cellWidth: 0.95,
                cellPadding: { right: 0.05 },
              },
            },
          };

          opsi.startY = marginTop + 9 * oneLineHeight;
          opsi.body = dataHal1;
          doc.autoTable(opsi);

          for (; 0 < detailTagihans.length; ) {
            doc.addPage("a4");
            dataHal1 = detailTagihans.splice(0, 15);
            for (let i = 0; i < dataHal1.length; i++) {
              dataHal1[i].no = noUrut;
              dataHal1[i].biaya = dataHal1[i].biaya
                ? new Intl.NumberFormat("id-ID", formatUang).format(
                    dataHal1[i].biaya
                  )
                : "-";

              noUrut++;
            }
            opsi.startY = marginTop + oneLineHeight;
            opsi.body = dataHal1;

            doc.autoTable(opsi);
          }

          let finalY = doc.lastAutoTable.finalY;

          if (finalY > 8.7) doc.addPage("a4");

          doc
            .setFont("times", "bold")
            .text(`Subtotal `, margin + 4.9, finalY + oneLineHeight);
          doc.text(`Tambahan Biaya `, margin + 4.9, finalY + 2 * oneLineHeight);
          doc.text(`Potongan Harga `, margin + 4.9, finalY + 3 * oneLineHeight);
          doc.text(`Pajak `, margin + 4.9, finalY + 4 * oneLineHeight);
          doc.text(`Total `, margin + 4.9, finalY + 5 * oneLineHeight);

          doc
            .setFont("times", "normal")
            .text(subtotal, margin + 6.1, (finalY += oneLineHeight));
          doc.text(tambahanBiaya, margin + 6.1, (finalY += oneLineHeight));
          doc.text(potonganHarga, margin + 6.1, (finalY += oneLineHeight));
          doc.text(pajak, margin + 6.1, (finalY += oneLineHeight));
          doc.text(total, margin + 6.1, (finalY += oneLineHeight));

          let infoFooter = doc
            .setFont("times", "normal")
            .splitTextToSize(
              `Pembayaran dapat dilakukan melalui transfer ke no rekening bank ${labelBank} (${kodeBank}) atas nama ${namaBank} dengan nomor rekening ${noRekening}. Untuk pembayaran melalui rekening lain harap menghubungi kami. Diharapkan melakukan konfirmasi dengan menyertakan nomor tagihan ${noTagihanVal} setelah melakukan pembayaran.`,
              maxLineWidth - 0.7
            );
          doc.text(infoFooter, margin, (finalY += 2 * oneLineHeight));

          if (finalY > 8.8) doc.addPage("a4");

          const body = [
            [
              { content: "" },
              { content: "Admin Keuangan, ", styles: { halign: "center" } },
            ],
            ["", "http://localhost:8080/files/pengguna/11/ttd-dr-wela.png"],
            ["", ""],
            ["", ""],
            [
              { content: "", styles: { cellPadding: { bottom: 0 } } },
              {
                content: namaAdminKeuangan ?? "",
                styles: { cellPadding: { bottom: 0, top: 0.1 } },
              },
            ],
            ["", ""],
          ];

          let tempCell;

          doc.autoTable({
            theme: "plain",
            body,
            tableWidth: "auto",
            startY: 9.1,
            margin: { left: 0.5 },
            columnStyles: {
              0: { font: "times", cellWidth: 4 },
              1: { font: "times", cellWidth: 3, halign: "center" },
            },
            rowPageBreak: "auto",
            styles: { cellPadding: { bottom: 0.08 } },
            didParseCell(data) {
              if (data.row.index === 4 && data.column.index === 1)
                tempCell = data.cell;
            },
            willDrawCell: function (data) {
              if (
                data.column.index === 1 &&
                data.row.index === 1 &&
                data.cell.section === "body"
              ) {
                var img = data.cell.raw;
                data.cell.text = "";

                // var img = td.getElementsByTagName('img')[0];
                // var dim = data.cell.height - data.cell.padding('vertical');
                var textPos = data.cell;
                doc.addImage(
                  "/files/logo/cap_ttd.png",
                  // "https://img.lovepik.com/element/40103/6703.png_300.png",
                  "PNG",
                  textPos.x,
                  textPos.y - 0.2,
                  1.8,
                  0.9
                );
                if (ttd) {
                  doc.addImage(
                    ttd,
                    // "https://img.lovepik.com/element/40103/6703.png_300.png",
                    "PNG",
                    textPos.x + 0.6,
                    textPos.y,
                    1.8,
                    0.9
                  );
                }
              }
            },
          });

          setTimeout(() => {
            doc.save(noTagihanVal + ".pdf");
            // pdfobject.embed(doc.output('datauristring'), '#showPdf')
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error " + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Load Data`,
            variant: "danger",
            solid: true,
          });
        });
    };

    const deleteButton = (id) => {
      g.$swal({
        title: "Apakah Anda Yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          $http({
            url: "/tagihan/" + id,
            method: "delete",
            headers: {
              Authorization: "Bearer " + g.$cookies.get("token"),
            },
          })
            .then(() => {
              datatable();
            })
            .catch((err) => {
              let showError = err.response
                ? err.response.data
                : err.request
                ? err.request
                : "Error" + err.message;

              g.$bvToast.toast(showError || "Something Wrong", {
                title: `Error Load Table`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    };

    const handleSearch = (searching) => {
      start.value = 1;
      datatable();
    };

    const handlePageChange = (active) => {
      start.value = 1;
      datatable();
    };

    const handleChangePage = (page) => {
      start.value = (page - 1) * row.value + 1;
      datatable();
    };

    const onSortChange = (params) => {
      sortColumn.value = params[0].field;
      order.value = params[0].type;
      datatable();
    };

    return {
      datatable,
      handleSearch,
      handlePageChange,
      handleChangePage,
      onSortChange,
      deleteButton,
      submitFilter,
      generatePdf,
      dataStatus,
      statusFilter,
      rows,
      searchTerm,
      start,
      row,
      totalRecords,
      sortColumn,
      order,
      isLoading,
    };
  },
  components: {
    VueGoodTable,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormInput,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BFormGroup,
    BFormSelect,
    BForm,
  },
  data() {
    return {
      columns: [
        {
          label: "No",
          field: "no",
          width: "1%",
        },
        {
          label: "No Tagihan",
          field: "noTagihan",
        },
        {
          label: "Tujuan",
          field: "tujuan",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Total (Rp)",
          field: "total",
        },
        {
          label: "Tanggal Kirim",
          field: "tanggalKirim",
        },
        {
          label: "Tanggal Bayar",
          field: "tanggalBayar",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Aksi",
          field: "aksi",
          width: "1%",
          sortable: false,
        },
      ],
    };
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
};
</script>

<style lang="scss" scope>
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.pdfobject-container {
  height: 80rem;
  border: 1rem solid rgba(0, 0, 0, 0.1);
}
</style>